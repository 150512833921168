import { createMuiTheme } from '@material-ui/core/styles';
//import { pink, red } from '@material-ui/core/colors';

const makeTheme = (userThemeJson) =>{
	const newTheme = createMuiTheme({
		palette: {
			primary: { main: userThemeJson ? userThemeJson.primary : '#222222' +
					'', contrastText: '#fff' },
			newPrimary: { main: userThemeJson ? userThemeJson.primary : '#748AA8', contrastText: '#000' },
			secondary: { main: userThemeJson ? userThemeJson.secondary : '#3688CC', contrastText: '#fff' },
		},
		typography: {
			useNextVariants: true,
			fontFamily: '"Raleway", "Helvetica", "Arial", sans-serif'
		},
		body:{
			backgroundColor:'#111'
		}
	})
	
	return newTheme;
}
export default makeTheme;
