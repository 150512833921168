/* eslint-disable */
import './App.css';
import React, { Suspense, useState, lazy, useMemo, useEffect, useCallback } from 'react';
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider, useQuery, useQueryClient } from "react-query";
import { QueryCache, ReactQueryCacheProvider } from 'react-query';
import { ReactQueryDevtools } from "react-query/devtools";
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { CssBaseline, LinearProgress, Typography } from '@material-ui/core/';
import makeTheme from './constants/theme';
import UserContext from './contexts/UserContext';
import useUser from './hooks/useUser';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Analytics from 'react-router-ga';


const HomePage = lazy(() => import('./pages/HomePage'));
const MakeLogoPage = lazy(() => import('./pages/MakeLogoPage'));
const AdminPage = lazy(() => import('./pages/AdminPage'));
const Profile = lazy(() => import('./pages/Profile'));
const SettingsContainer = lazy(() => import('./pages/Settings'));
const DashboardContainer = lazy(() => import('./pages/Dashboard'));

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
		},
	},
});
const queryCache = new QueryCache();
const createUUID = () => {
	var dt = new Date().getTime();
	var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
		var r = (dt + Math.random()*16)%16 | 0;
		dt = Math.floor(dt/16);
		return (c=='x' ? r :(r&0x3|0x8)).toString(16);
	});
	return uuid;
}
const AppContainer = () => {
	return (
		<>
		<QueryClientProvider client={queryClient}>
			<App/>
		</QueryClientProvider>
		</>
	);
}

const App = () =>{
	
	const [chosenProfileIndex, setChosenProfileIndex] = useState(localStorage.getItem('profileIndex') ? localStorage.getItem('profileIndex') : 0);
	const [userToken, setUserToken] = useState(localStorage.getItem('token') ? localStorage.getItem('token') : '');
	const [anonId, setAnonId] = useState(localStorage.getItem('anon') ? localStorage.getItem('anon') : localStorage.setItem('anon',createUUID()));
	const [actualThemeJson, setActualThemeJson] = useState(null);
	
	const login = (user,token)=> {
		setUserToken(token);
		localStorage.setItem('token', token);
	}
	const logout = ()=> {
		setUserToken(null);
		queryClient.invalidateQueries('profile')
		localStorage.setItem('token', '');
	}
	const { status, data, error, isFetching } = useUser(userToken);
	if(status==="success" && data && data.isValid===false && data.status===401 && userToken!==''){
		logout();
	}
	const profile = data && data.user ? data.user[chosenProfileIndex] : undefined;
	const links = data && data.links ? data.links : undefined;
	const purchases = data && data.purchases ? data.purchases : undefined;
	const changeTheme = (theme) => {
		if(theme===undefined){
			return false;
		}
		if(userThemeJson){
			setActualThemeJson({ theme,...userThemeJson });
		}else{
			setActualThemeJson(theme);
		}
	}
	
	const changeProfile = useCallback((id) => {
		setChosenProfileIndex(id)
	},[]);
	const userObj={
		userArray:data && data.user ? data.user : undefined,
		user:profile,
		links:links,
		purchases:purchases,
		token:userToken,
		loginUser:login,
		logoutUser:logout,
		changeTheme:changeTheme,
		changeProfile:changeProfile,
		chosenProfileIndex:chosenProfileIndex,
		anonId: anonId
	}
	const userThemeJson = profile && profile.linkssTheme!==undefined ? JSON.parse(profile.linkssTheme) : undefined;
	const userTheme = useMemo(()=>actualThemeJson ? makeTheme(actualThemeJson) : makeTheme(userThemeJson),[actualThemeJson]);
	

	return (
	<ThemeProvider theme={userTheme}>
		<CssBaseline />
		
		{status=="success" || status=="error" || status==undefined
		?
			<>
				<UserContext.Provider value={userObj}>
					<Suspense fallback={<LinearProgress style={{ margin: '20% auto', width:'80%', backgroundColor:'#111' }} />}>
						<Router>
							<Analytics id="UA-154880-10" trackPathnameOnly>
							<Switch>
								<Route exact path="/">
									<HomePage />
								</Route>
								{(userToken === "" || userToken === null) &&
								<Route exact path="/reset/:resetToken">
									<HomePage resettingPw={true} />
								</Route>
								}
								<Route exact path="/makelogo">
									<MakeLogoPage  />
								</Route>
								{(profile && profile.linkssRole==="admin") &&
								<Route exact path="/admin">
									<AdminPage token={userToken}/>
								</Route>
								}
								{userToken===null && <Redirect to='/' />}
									<Route exact path="/profile">
										{profile!==undefined ?<Profile user={profile}  token={userToken} /> : <Redirect to='/' />}
									</Route>
									<Route exact path="/settings">
										{profile!==undefined ?<SettingsContainer token={userToken} /> : <Redirect to='/' />}
									</Route>
									<Route exact path="/dashboard">
										{profile!==undefined ?<DashboardContainer token={userToken} /> : <Redirect to='/' />}
									</Route>
								<Route exact path="/:userSlug">
									<Profile token={userToken}  />
								</Route>
								<Redirect to='/' />
							</Switch>
							</Analytics>
						</Router>
					</Suspense>
				</UserContext.Provider>
			</>
			:
<LinearProgress style={{ margin: '20% auto', width:'80%', backgroundColor:'#111' }}/>
		}
	</ThemeProvider>)
}

export default AppContainer;
