import { useQuery } from "react-query";
import postValidate from '../api/postValidate'

export default function useUser(token) {
  const result = useQuery('loggedInUser', () => postValidate(token),
    {
      // Refetch the data every second
      refetchInterval: (token==='' || token===undefined) ? false : (60000*5),
    }); // - 60 seconds
  return result;
}