import  axios from 'axios'

const postValidate = async (token) => {
  const apiURL = process.env.REACT_APP_API_URL + 'public/validate'
  const requestParams = {
      token: token,
  }
  try {
    const response = await axios.post(apiURL, requestParams)
    return response.data;
  } catch (error) {
    console.error(error.response.data.msg);
    return error.response; //.data.msg || '';
  }
};
export default postValidate;